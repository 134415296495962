@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");

.section {
  margin-top: 3rem;
  margin-bottom: 5rem;  
  z-index: 1000;
}

html {
  scroll-behavior: smooth;
}

.bd-grid {
  max-width: 1024px;
  display: grid;
  grid-template-columns: 100%;
  grid-column-gap: 2rem;
  width: calc(100% - 2rem);
  margin: auto;
}

.home {
  height: 100vh;
  position: relative;
}

.home_data {
  align-self: center;
}

.home_social {
  padding-top: 2rem;
  flex-direction: row;
  align-self: flex-end;
}

.home_social-icon {
  width: max-content;
  font-size: 1.75rem;
  color: white;
}

.particles {
  width: 100%;
  position: fixed;
  z-index: -10000;
}

.animation_background {
  background-color: #08090d;
  width: 100%;
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0; 
}